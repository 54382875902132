import React from 'react'
import {
  Form,
  Table,
} from 'react-bootstrap'
import ConnaissementService from '../../services/connaissements/ConnaissementServices'
import OrdersService from '../../services/orders/OrdersService'
import userStore from '../../stores/userStore'
import { errorType } from '../../definitions/errorType'
import { ToastDeleteSuccess, ToastError, ToastInfo, ToastUpdateUserSuccess } from '../../component/ui/Toast/Toastes'
import {
  DetailConnaissementModal,
  DeleteModal,
  EditConnaissementModal,
  MultiDeleteModal,
  NbPaletteModal,
  QrcodeConnaissementModal,
  UpdateMultiToDemandeModal,
  UpdateToDemandeModal,
} from '../../component/ui/Modal/ConnaissementModals'
import { _getMultiPDFConnaissementByEvenement, _refreshToken } from '../../utils/api/apiControlerFunctions'
import { useReactToPrint } from 'react-to-print'
import PaginationZero from '../../component/ui/PaginationZero'
import BillOfTableTitle from '../../component/billOfLading/BillOfTableTitle'
import BillOfFilter from '../../component/billOfLading/BillOfFilter'
import ItemsLimiter from '../../component/billOfLading/ItemsLimiter'
import ConnaissementServices from '../../services/connaissements/ConnaissementServices'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import NoResult from '../../component/ui/error/NoResult'
import ErrorConnaissementMessage from '../../component/ui/error/ErrorConnaissementMessage'
import SearchBar from '../../component/billOfLading/SearchBar'
import { ResultConnaissementType } from '../../definitions/ConnaissementType'
import TableLoader from '../../component/ui/Loader/TableLoader'
import ItemBillOfLading from '../../component/billOfLading/ItemBillOfLading'
import MultiAction from '../../component/billOfLading/MultiAction'
import MultiPrintViewer from '../../component/billOfLading/MultiPrintViewer'
import { _getOrdersByOneIdBill } from '../../utils/api/totaraApi'

export default function BillOfLading() {
   const { filteringData, setFilteringData, connaissementData, setConnaissementData, isNotification, setIsNotification } = useOutletContext<any>()
  const dataStore = userStore((state: any) => state)
  const navigate = useNavigate()
  const params = useParams()

  const isTransporter : boolean = dataStore?.roles && dataStore?.roles[0]?.name === 'transporteur'

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isErrorConnaiss, setIsErrorConnaiss] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>("")
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  const [isEdit, setIsEdit] = React.useState<boolean>(false)

  // const [connaissementData, setConnaissementData] = React.useState<any>([])
  const [selectedConnaissement, setSelectedConnaissement] = React.useState<ResultConnaissementType>()
  const [checkedMultiConnaissement, setCheckedMultiConnaissement] = React.useState<any>([])
  const [checkedConnaissement, setCheckedConnaissement] = React.useState<number[]>([])
  const [idsOrder, setIdsOrder] = React.useState<any>([])
 
  const [nbPalette, setNbPalette] = React.useState<number>(0)

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [itemPerPage, setItemPerPage] = React.useState<number>(20)

  const [sortConfig, setSortConfig] = React?.useState<string >("id,desc");

  const isEmpty =
    filteringData?.numeroConnaissement === '' &&
    filteringData?.expediteur_denomination === '' &&
    filteringData?.destinataire === '' &&
    filteringData?.idNavire === '' &&
    filteringData?.evenementConnaissement === '' &&
    filteringData?.dateDepart === '' &&
    filteringData?.nomIleArrivee === '' &&
    filteringData?.dateArrivee === ''



  //////////////////
  //Toastes
  /////////////////
  //Toast Delete success
  const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false)
  const toggleShowDeleteSuccess = () => setShowDeleteSuccess(!showDeleteSuccess)

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false)
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess)

  const [showOrderError, setShowOrderError] = React.useState<boolean>(false)
  const toggleShowOrderError = () => setShowOrderError(!showOrderError)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

  //////////////////
  //Modals
  /////////////////
  //detail connaissement
  const [showDetailConnaiss, setShowDetailConnaiss] = React.useState(false)
  const handleCloseDetailConnaiss = () => {
    setShowDetailConnaiss(false)
  }
  const handleShowDetailConnaiss = () => setShowDetailConnaiss(true)

  //edit connaiss
  const [showUpdate, setShowUpdate] = React.useState(false)
  const handleCloseUpdate = () => {
   
    setShowUpdate(false)
  }
  const handleShowUpdate = () => setShowUpdate(true)
  //update palette count
  const [showUpdatePalette, setShowUpdatePalette] = React.useState(false)
  const handleCloseUpdatePalette = () => {
    setShowUpdatePalette(false)
  }
  const handleShowUpdatePalette = () => setShowUpdatePalette(true)

  //Show qrcode
  const [showQrcode, setShowQrcode] = React.useState(false)
  const handleCloseQrcode = () => {
    setShowQrcode(false)
  }
  const handleShowQrcode = () => setShowQrcode(true)

  //Update connaissement to demande
  const [showUpdateToDemandeModal, setShowUpdateToDemandeModal] = React.useState(false)
  const handleCloseUpdateToDemandeModal = () => {
    setIdsOrder([])
    setShowUpdateToDemandeModal(false)
  }
  const handleShowUpdateToDemandeModal = () => {
    setShowUpdateToDemandeModal(true)
  }

  //Update multi connaissement to demande
  const [showUpdateMultiToDemandeModal, setShowUpdateMultiToDemandeModal] =
    React.useState(false)
  const handleCloseUpdateMultiToDemandeModal = () => {
    setShowUpdateMultiToDemandeModal(false)
  }
  const handleShowUpdateMultiToDemandeModal = () => {
    getOrderSByIDBill()
    setShowUpdateMultiToDemandeModal(true)
  }

  //Delete connaissement modal
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const handleCloseDeleteModal = () => {
    setIdsOrder([])
    setShowDeleteModal(false)
  }
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true)  
  }

  //multi Delete connaissement modal
  const [showMultiDeleteModal, setShowMultiDeleteModal] = React.useState(false)
  const handleCloseMultiDeleteModal = () => {
    setIdsOrder([])
    setShowMultiDeleteModal(false)
  }
  const handleShowMultiDeleteModal = () => {
    setShowMultiDeleteModal(true)
  }

  //print block ref
  const printRef = React.useRef(null)
  const printref = React.useRef<HTMLDivElement>(null)

  // Utiliser useReactToPrint pour gérer l'impression
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })
  const handlePrintMulti = useReactToPrint({
    content: () => printref.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })
  const evnt = undefined;

  

/////////////////////
//useEffect section
/////////////////////
React.useEffect(() => {
  // Initial data table load
  if (isEmpty || !isFiltering || sortConfig !== 'id,desc' || isEdit) {
    if (isEmpty) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage, sortConfig);
          setIsFiltering(false);
      }
      if (isEdit) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage, "id,desc")
          setIsEdit(false);
      }
      if (isFiltering || sortConfig !== 'id,desc') {
        filterConnaissement(
          evnt,
          dataStore.access_token,
          filteringData,
          currentPage,
          itemPerPage,
          sortConfig
        )
      }
  }
  if(isNotification === true){
      filterConnaissement(evnt, dataStore.access_token, filteringData, currentPage, itemPerPage, sortConfig)
      setIsNotification(false)
   
  }
 

  // Navigation for transporter
  if (isTransporter) {
    //transporter restriction
      navigate('/connaissements-demande');
  }


}, [isNotification, isEmpty, isFiltering, sortConfig, currentPage, itemPerPage, isEdit, params?.numConnaissement, filteringData, isTransporter]);

  // React.useEffect(() => {
    

  //     connaissementDataTable(currentPage, setTotalPages, itemPerPage, sortConfig)
      
    
  //   if(isTransporter){
  //     navigate('/connaissements-demande')
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (isEmpty) {
  //     connaissementDataTable(currentPage, setTotalPages, itemPerPage, sortConfig)
     
  //     setIsFiltering(false)
  //   }
  //   if((filteringData?.numeroConnaissement !== ''&& filteringData?.numeroConnaissement !== 'connaissements')){
  //     filterConnaissement(evnt, dataStore.access_token, filteringData, currentPage, itemPerPage, sortConfig)
      
  //   }
   
  // }, [filteringData])
  

  // React.useEffect(() => {
  //   if (isFiltering || sortConfig !== 'id,desc') {
  //     filterConnaissement(evnt, dataStore.access_token, filteringData, currentPage, itemPerPage, sortConfig)
  //   }else{
  //     connaissementDataTable(currentPage, setTotalPages, itemPerPage, sortConfig)
  //   }
  // }, [sortConfig, currentPage, itemPerPage])
  

  // React.useEffect(() => {
  //   if(isEdit){
  //     connaissementDataTable(currentPage, setTotalPages, itemPerPage, "id,desc")
  //     setIsEdit(false)
  //   }
  // }, [isEdit])
  
  // React.useEffect(() => {
  //   if(params?.numConnaissement  && params?.numConnaissement !== 'connaissements'){
  //     setFilteringData({
  //       ...filteringData,
  //       numeroConnaissement:  params?.numConnaissement.replace(/-(?=[^-]*$)/, "/")
  //     })
  //   }
  // }, [params?.numConnaissement])



 


  /////////////////////
  //events
  ////////////////////


  const getOrderSByIDBill = () => {
    setIsError({
      error: false,
      message: '',
    })
    try {
      checkedConnaissement?.map((id: number) => {
      return  OrdersService.getOrdersByIdConnaissement(dataStore.token, id)
          .then((response: any) => {
            const checkedOrder = response?.data?.data?.map((order: any) => {
            return  idsOrder.push(order?.id)
            })
            console.log(checkedOrder)
          })
          .catch((error: any) => {
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
            toggleShowOrderError()
          })
      })

    } catch (error) {
      console.log(error)
    }
  }

  const filterConnaissement = async (event: any | undefined, token: any,  filteringData: any, currentPage: number, itemPerPage: number, sort: string) => {
    if(event !== undefined){
      event.preventDefault()
    }
    let filteredParams
     //Si le status demandé fait parti de la liste, on applique le statut OFFICIALISE
     if (
       filteringData.evenementConnaissement === 'OFFICIALISE' ||
       filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
       filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
       filteringData.evenementConnaissement === 'EMBARQUE' ||
       filteringData.evenementConnaissement === 'MODIFIE' ||
       filteringData.evenementConnaissement === 'TRANSFERE' ||
       filteringData.evenementConnaissement === 'SAISIE'
     ) {
       const newData = {
         ...filteringData,
         evenementConnaissement: 'OFFICIALISE',
       }

       // Filtrer les paramètres qui ne sont pas définis ou sont vides
       filteredParams = Object.entries(newData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     } else {
       filteredParams = Object.entries(filteringData)
         .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
         .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
         .join('&') // Les concatène avec '&'
     }

     setIsLoading(true)

    try{

      // const responseFilteredConnaissement = await ConnaissementService.getFilteredConnaissement(token, currentPage, filteredParams, itemPerPage)
      const responseFilteredConnaissement = await ConnaissementService.getFilteredConnaissementSort(token, currentPage, filteredParams, itemPerPage, sort)


  let sortedData: any = []
  //Si le status demandé fait parti de la liste, on refiltre les données par un de ces statuts
  if (
    filteringData.evenementConnaissement === 'OFFICIALISE' ||
    filteringData.evenementConnaissement === 'EMBARQUEMENT_REFUSE' ||
    filteringData.evenementConnaissement === 'PRIS_EN_CHARGE' ||
    filteringData.evenementConnaissement === 'EMBARQUE' ||
    filteringData.evenementConnaissement === 'MODIFIE' ||
    filteringData.evenementConnaissement === 'TRANSFERE' ||
    filteringData.evenementConnaissement === 'SAISIE'
  ) {


    const officialiseData = responseFilteredConnaissement?.data?.content?.filter(
      (data: any) =>
        data.dernierEtat?.evenementConnaissement === filteringData.evenementConnaissement
    )
    console.log(officialiseData)

    sortedData = officialiseData?.sort((a: any, b: any) => b.id - a.id)
  } else {
    sortedData = responseFilteredConnaissement?.data?.content?.sort(
      (a: any, b: any) => b.id - a.id
    )
  }
  

  if (filteringData?.dateDepart || filteringData?.dateArrivee) {
    const inputDateDepart = filteringData.dateDepart?.trim() ?? ''
    const inputDateArrivee = filteringData.dateArrivee?.trim() ?? ''

    if (inputDateArrivee?.length > 2 || inputDateDepart.length > 2) {
      const filteredData = sortedData.filter((item: any) => {
        // logique pour la date de départ
        const fullDateDepart = item?.voyage?.dateDepart || ''
        const itemDateDepartFormatted = fullDateDepart.split('-').reverse().join('/') // "12/11/2024"

        // Comparaison partielle pour la date de départ
        const matchDateDepart =
          !inputDateDepart ||
          itemDateDepartFormatted.includes(inputDateDepart) || // Recherche partielle
          itemDateDepartFormatted.startsWith(inputDateDepart) // Recherche début correspond

        // logique pour la date d'arrivée
        const fullDateArrivee = item?.voyage?.periple?.filter(
          (periple: any) => periple?.ileArrivee.nom === item?.ileArrivee?.nom
        )[0]
        const itemDateArriveeFormatted = fullDateArrivee.dateArrivee
          .split('-')
          .reverse()
          .join('/') // "12/11/2024"

        // Comparaison partielle pour la date d'arrivée
        const matchDateArrivee =
          !inputDateArrivee ||
          itemDateArriveeFormatted.includes(inputDateArrivee) || // Recherche partielle
          itemDateArriveeFormatted.startsWith(inputDateArrivee) // Recherche début correspond

      
        return (
          matchDateArrivee && matchDateDepart
        )
      })
      setConnaissementData(filteredData)
      setTotalPages(responseFilteredConnaissement?.data?.totalPages)
    } else {
      setConnaissementData(sortedData)
      setTotalPages(responseFilteredConnaissement?.data?.totalPages)
    }
  } else {
    setConnaissementData(sortedData)
    setTotalPages(responseFilteredConnaissement?.data?.totalPages)
  }

      setIsLoading(false)
      setIsFiltering(true)
    }catch(error){
      setIsLoading(false)
      console.log(error)
      setIsFiltering(true)
    }
  }

  const handleFilterConnaissement = (event: any) => {
    const value = event?.target?.value
    if (value?.length > 2) {
      const filteredData = connaissementData.filter((item: any) => {
        return (
          item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
          item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
          item?.dernierEtat?.evenementConnaissement
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
        )
      })

      setConnaissementData(filteredData)
    }
    if (value.length === 0) {
      // connaissementDataTable(0, setTotalPages, itemPerPage, "id,desc")
    }
  }

  
  const connaissementDataTable = async (currentPage: number, setTotalPages: any, itemPerPage: number, sort: string) => {
    setIsLoading(true)
   
    try {
      // const response = await ConnaissementService.getConnaissement(
      const response = await ConnaissementService.getConnaissementSort(
        dataStore.access_token,
        currentPage,
        itemPerPage,
        sort
      )
      if (response?.status === 200) {
        setIsErrorConnaiss({
          error: false,
          message: '',
        })
      }
      const sortedData = response?.data?.content?.sort((a: any, b: any) => b.id - a.id)
      setTotalPages(response?.data?.totalPages)
      setConnaissementData(sortedData)
      setIsLoading(false)
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Une erreur est survenue. Veuillez réessayer plus tard." : error?.message
      })
      setIsLoading(false)

      if (error?.response?.data?.error === 'invalid_token') {
        setIsError({
          error: true,
          message: error?.response?.data?.error
        })
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }

  const updateBrouillonConnaissement = async (token: string, id: number) => {
    const bodyData = {
      evenementConnaissementEnum: 'DEMANDE',
      demandeParArmateur: false,
    }

    try {
      const response = await ConnaissementService.updateConnaissement(token, bodyData, id)
      
      if (response.status === 200) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage, "id,desc")
        toggleShowUpdateSuccess()
        handleCloseUpdateToDemandeModal()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard." : error?.message
      })
    }
  }
  
  const updateNbPaletteConnaissement = async (token: string, data: any) => {
    const connaissDetail = data.detailConnaissements?.map((prod: any) => {
      return {
        codeSH: prod?.codeSH.nomenclature,
        codeTarif: prod?.codeTarif.code,
        description: prod?.description,
        nbColis: prod?.nbColis,
        poids: prod?.poids,
        stockage: prod?.stockage,
        unitePoids: prod?.unitePoids,
      }
    })


    const bodyData = {
      "version": data?.version,
      "detailConnaissementDTO": connaissDetail,
      "expediteur": data?.expediteur,
      "destinataire": data?.destinataire,
      "paiement": data?.paiement,
      "numeroVoyage": data?.voyage?.numero,
      "ileDepart": data?.ileDepart.nom,
      "lieuDepart": data?.lieuDepart.nom,
      "ileArrivee": data?.ileArrivee.nom,
      "lieuArrivee": data?.lieuArrivee.nom,
      "nombreColisAEmbarquer": nbPalette

    }

    try {

      const response = await ConnaissementService.updateNbPalette(token, bodyData, data?.id)
      if (response.status === 200) {
        toggleShowUpdateSuccess()
        handleCloseUpdatePalette()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      const errorMessage =
        error?.message === 'Network Error'
          ? 'Une erreur est survenue. Veuillez vérifier votre connexion et réessayer plus tard.'
          : error?.response?.data?.error || error?.response?.data?.message

          const errorDetail = Array.isArray(error?.response?.data?.detail)
          ? error?.response?.data?.detail[0]
            ? ` : ${error?.response?.data?.detail[0]?.origine || ''} ${error?.response?.data?.detail[0]?.cause || ''}`
            : ''
          : typeof error?.response?.data?.detail === 'string'
            ? ` : ${error?.response?.data?.detail}`
            : '';
      setIsError({
        error: true,
        message: `${errorMessage || error?.message}${errorDetail}`
      })
   
    }
  }

  const deleteBrouillonConnaissement = async (token: string, id: number) => {
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await ConnaissementService.deleteBrouillon(token, id)
      const deletedList = connaissementData?.filter((bill: any) => bill?.id !== id)
      if (response.status === 204) {
        // connaissementDataTable(0, setTotalPages, itemPerPage)
        toggleShowDeleteSuccess()
        handleCloseDeleteModal()
      setConnaissementData(deletedList)
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
      toggleShowOrderError()
    }
  }

  //Gère les connaissements selectionnées
  const handleSelectConnaissement = (bill: any) => {
    if (checkedConnaissement.includes(bill)) {
      // ()
      setCheckedConnaissement(checkedConnaissement?.filter((item: any) => item !== bill))
    } else {
      setCheckedConnaissement([...checkedConnaissement, bill])
    }
  }

  const multiselected = (bill: any) => {
    if (checkedMultiConnaissement.some((item: any) => item.id === bill.id)) {
      setCheckedMultiConnaissement(checkedMultiConnaissement?.filter((item: any) => item?.id !== bill?.id))
    } else {
      setCheckedMultiConnaissement([...checkedMultiConnaissement, bill])
    }
  }



  const [pdfData, setPdfData] = React.useState<any>();

  const getMultiPDFConnaissementByEvenement =  (
    token: string,
    setPdfData: any ) => {
    try{

      const response = checkedMultiConnaissement?.map((item: any) =>
        ConnaissementServices.getPDFConnaissement(
        token,
        item?.id,
        item?.dernierEtat?.id,
        item?.numero,
        setPdfData
      ))
      Promise.all([
          ...(response || [])
        ]);

    }catch(error){
      console.log(error)
    }
  }


  //sélectionner tous les connaissements
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validedData = connaissementData
      //   .filter((connaiss: any) => connaiss.dernierEtat.evenementConnaissement === 'BROUILLON')
        ?.map((item: any) => item.id)
        setCheckedConnaissement(validedData)
        setCheckedMultiConnaissement(connaissementData)
    } else {
      setCheckedConnaissement([])
      setCheckedMultiConnaissement([])
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
   
  }
  //Components props
  const BillOfTableTitleProps = {sortConfig, setSortConfig}
  const billOfilterProps = {
    handleSelectAll,
    filteringData,
    setFilteringData,
    isTransporter,
    isFiltering,
    isEmpty,
    isLoading,
    sortConfig,
    setSortConfig,
  }

//Modals props
  const detailOrderModalProps = {
    showDetailConnaiss,
    selectedConnaissement,
    handleCloseDetailConnaiss,
    handlePrint,
    printRef,
  }
  const qrCodeModalProps = { showQrcode, selectedConnaissement, handleCloseQrcode }

  const updateToDemandeModalProps = {
    showUpdateToDemandeModal,
    handleCloseUpdateToDemandeModal,
    selectedConnaissement,
    updateBrouillonConnaissement,
    idsOrder,
    setIdsOrder,
    setIsError,
    toggleShowOrderError,
  }
  const updatePaletteModalProps = {
    showUpdatePalette,
    handleCloseUpdatePalette,
    selectedConnaissement,
    setSelectedConnaissement,
    setConnaissementData,
    connaissementData,
    setNbPalette,
    updateNbPaletteConnaissement
  }
  const updateMultiToDemandeModalProps = {
    showUpdateMultiToDemandeModal,
    handleCloseUpdateMultiToDemandeModal,
    checkedConnaissement,
    setCheckedConnaissement,
    connaissementDataTable,
    toggleShowUpdateSuccess,
    setIsError,
    toggleShowOrderError,
    idsOrder,
    setIdsOrder,
    currentPage, setTotalPages, itemPerPage
  }
  const deleteBillOfLadingProps = {
    showDeleteModal,
    handleCloseDeleteModal,
    selectedConnaissement,
    deleteBrouillonConnaissement,
    idsOrder, setIdsOrder,
    setIsError,
    toggleShowOrderError,
    setShowOrderError,
  }
  const multiDeleteBillOfLadingProps ={
    showMultiDeleteModal,
    handleCloseMultiDeleteModal,
    checkedMultiConnaissement,
    deleteBrouillonConnaissement,
    idsOrder,
    setIsError,
    toggleShowOrderError,
  }

  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
  const toastDeleteSuccessProps = { showDeleteSuccess, toggleShowDeleteSuccess }
  const toastErrorProps = {showOrderError, toggleShowOrderError, isError}; 
  const toastInfoProps = {showInfo, toggleShowInfo, infoOrder}; 

  const editConnaissementModal = {showUpdate, handleCloseUpdate, selectedConnaissement, setIsError, toggleShowOrderError, setIsEdit}

  
 

  const isNotJustOfficialse = checkedMultiConnaissement?.filter((bill: any) => bill.dernierEtat.evenementConnaissement !== "OFFICIALISE" && bill.dernierEtat.evenementConnaissement !== "EMBARQUE")
  const isNotJustBrouillon = checkedMultiConnaissement?.filter((bill: any) => bill.dernierEtat.evenementConnaissement !== "BROUILLON")

  const isDeletable = checkedMultiConnaissement.some(
    (item: any) =>
      item.dernierEtat.evenementConnaissement !== 'BROUILLON' &&
      item.dernierEtat.evenementConnaissement !== 'DEMANDE_REFUSEE'
  )
  


  return (
    <div className='p-1 p-sm-3'>
      <h3 className='text-secondary'>Connaissements</h3>
      <SearchBar handleFilterConnaissement={handleFilterConnaissement} />
      <Form
        onSubmit={(event) =>
          filterConnaissement(
            event,
            dataStore.access_token,
            filteringData,
            currentPage,
            itemPerPage,
            sortConfig
          )
        }
      >
        <Table striped hover responsive className='responsive-font-small border'>
          <BillOfTableTitle BillOfTableTitleProps={BillOfTableTitleProps} />
          <BillOfFilter billOfilterProps={billOfilterProps} />
          <tbody>
            {!isLoading &&
              connaissementData?.length > 0 &&
              connaissementData?.map((connaissement: any, indx: number) => {
                const isNotBrouillon =
                  connaissement?.dernierEtat?.evenementConnaissement !== 'BROUILLON'

                const isRefusee =
                  connaissement?.dernierEtat?.evenementConnaissement === 'ANNULE' ||
                  connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE_REFUSEE' ||
                  connaissement?.dernierEtat?.evenementConnaissement === 'EMBARQUEMENT_REFUSE'

                const isDemande =
                  connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE' ||
                  connaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE' ||
                  connaissement?.dernierEtat?.evenementConnaissement ===
                    'OFFICIALISE_SOUS_RESERVE'

                const isRedBackGround =
                  connaissement?.dernierEtat?.evenementConnaissement ===
                    'EMBARQUEMENT_REFUSE' ||
                  connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE_REFUSEE' ||
                  connaissement?.dernierEtat?.evenementConnaissement === 'ANNULE'

                const isDangerous = connaissement.detailConnaissements.some(
                  (detail: any) => detail.matiereDangereuse === true
                )

                const isFragile = connaissement.detailConnaissements.some(
                  (detail: any) => detail.fragile === true
                )

                const dateDArrivee = connaissement?.voyage?.periple?.filter(
                  (periple: any) => periple?.ileArrivee.nom === connaissement?.ileArrivee?.nom
                )[0]
                const ItemBillOfLadingProps = {
                  isRedBackGround,
                  connaissement,
                  checkedConnaissement,
                  handleSelectConnaissement,
                  multiselected,
                  setSelectedConnaissement,
                  handleShowDetailConnaiss,
                  isTransporter,
                  dateDArrivee,
                  isDangerous,
                  isFragile,
                  isDemande,
                  isRefusee,
                  handleShowQrcode,
                  isNotBrouillon,
                  handleShowUpdateToDemandeModal,
                  handleShowUpdatePalette,
                  handleShowDeleteModal,
                  handleShowUpdate,
                }

                return (
                  <ItemBillOfLading key={indx} ItemBillOfLadingProps={ItemBillOfLadingProps} />
                )
              })}
            {isLoading &&
              Array.from({ length: 5 }).map((_: any, index: number) => (
                <TableLoader colNumber={8} key={index} />
              ))}
            {!isLoading &&
              !isError.error &&
              isFiltering &&
              connaissementData?.length === 0 && <NoResult />}

            {!isLoading && isErrorConnaiss.error && (
              <ErrorConnaissementMessage
                isTransporter={isTransporter}
                isErrorConnaiss={isErrorConnaiss}
              />
            )}
          </tbody>
        </Table>
      </Form>

      <div className='d-flex align-items-center mb-5'>
        <PaginationZero
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>
      {/* <div className='table__responsive'>
        <div className='table__row table__header'>
          <div className='table__cell'>Nom</div>
          <div className='table__cell'>Âge</div>
          <div className='table__cell'>Ville</div>
          <div className='table__cell'>Nom</div>
          <div className='table__cell'>Âge</div>
          <div className='table__cell'>Ville</div>
          <div className='table__cell'>Nom</div>
          <div className='table__cell'>Âge</div>
          <div className='table__cell'>Ville</div>
        </div>

        <div className='table__row'>
          <div className='table__cell'>Alice</div>
          <div className='table__cell'>25</div>
          <div className='table__cell'>Paris</div>
          <div className='table__cell'>Alice</div>
          <div className='table__cell'>25</div>
          <div className='table__cell'>Paris</div>
          <div className='table__cell'>Alice</div>
          <div className='table__cell'>25</div>
          <div className='table__cell'>Paris</div>
        </div>
        <div className='table__row'>
          <div className='table__cell'>Bob</div>
          <div className='table__cell'>30</div>
          <div className='table__cell'>Lyon</div>
          <div className='table__cell'>Bob</div>
          <div className='table__cell'>30</div>
          <div className='table__cell'>Lyon</div>
          <div className='table__cell'>Bob</div>
          <div className='table__cell'>30</div>
          <div className='table__cell'>Lyon</div>
        </div>
        <div className='table__row'>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
        </div>
        <div className='table__row'>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
          <div className='table__cell'>Charlie</div>
          <div className='table__cell'>35</div>
          <div className='table__cell'>Marseille</div>
        </div>
      </div> */}
      {checkedConnaissement?.length > 1 && (
        <MultiAction
          checkedConnaissement={checkedConnaissement}
          isNotJustOfficialse={isNotJustOfficialse}
          getMultiPDFConnaissementByEvenement={getMultiPDFConnaissementByEvenement}
          dataStore={dataStore}
          setPdfData={setPdfData}
          handlePrintMulti={handlePrintMulti}
          handleShowUpdateMultiToDemandeModal={handleShowUpdateMultiToDemandeModal}
          isNotJustBrouillon={isNotJustBrouillon}
          isDeletable={isDeletable}
          handleShowMultiDeleteModal={handleShowMultiDeleteModal}
          setCheckedConnaissement={setCheckedConnaissement}
          setCheckedMultiConnaissement={setCheckedMultiConnaissement}
        />
      )}
      {/* {checkedConnaissement?.length > 1 && ( */}
      <MultiPrintViewer
        checkedMultiConnaissement={checkedMultiConnaissement}
        printref={printref}
      />
      {/* )} */}

      <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />
      <QrcodeConnaissementModal qrCodeModalProps={qrCodeModalProps} />
      <UpdateToDemandeModal updateToDemandeModalProps={updateToDemandeModalProps} />
      <NbPaletteModal updatePaletteModalProps={updatePaletteModalProps} />
      <UpdateMultiToDemandeModal
        updateMultiToDemandeModalProps={updateMultiToDemandeModalProps}
      />
      <DeleteModal deleteBillOfLadingProps={deleteBillOfLadingProps} />
      <MultiDeleteModal multiDeleteBillOfLadingProps={multiDeleteBillOfLadingProps} />

      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
      <ToastDeleteSuccess toastDeleteSuccessProps={toastDeleteSuccessProps} />
      <ToastError toastErrorProps={toastErrorProps} />
      <ToastInfo toastInfoProps={toastInfoProps} />

      <EditConnaissementModal editConnaissementModal={editConnaissementModal} />
    </div>
  )

}
