import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import DashBoard from './pages/private/DashBoard'
import Order from './pages/private/Order'
import BillOfLading from './pages/private/BillOfLading'
import TransporterDemande from './pages/private/transporter/TansporterDemande'
import TransporterPage from './pages/private/transporter/TransporterPage'
import TransporterSousReserve from './pages/private/transporter/TransporterSousReserve'
import EmbarquementRefuse from './pages/private/transporter/EmbarquementRefuse'
import DemandeRefusee from './pages/private/transporter/DemandeRefusee'
import Companies from './pages/private/Companies'
import Users from './pages/private/Users'
import PermissionManager from './pages/private/PermissionManager'
import Auth from './pages/public/Auth'
import NotFound from './pages/NotFound'
import ResetPassword from './pages/public/ResetPassword'
import 'animate.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'dashboard',
        element: (
          <React.Fragment>
            <DashBoard />
          </React.Fragment>
        ),
      },
      {
        path: 'factures',
        element: (
          <React.Fragment>
            <Order />
          </React.Fragment>
        ),
      },
      {
        path: 'connaissements',
        element: (
          <React.Fragment>
            <BillOfLading />
          </React.Fragment>
        ),
      },
      {
        path: 'connaissements-demande',
        element: (
          <React.Fragment>
            <TransporterDemande />
          </React.Fragment>
        ),
      },
      {
        path: 'connaissements-officialise',
        element: (
          <React.Fragment>
            <TransporterPage />
          </React.Fragment>
        ),
      },
      {
        path: 'connaissements-sous-reserve',
        element: (
          <React.Fragment>
            <TransporterSousReserve />
          </React.Fragment>
        ),
      },
      {
        path: 'embarquements-refuses',
        element: (
          <React.Fragment>
            <EmbarquementRefuse />
          </React.Fragment>
        ),
      },
      {
        path: 'demandes-refusees',
        element: (
          <React.Fragment>
            <DemandeRefusee />
          </React.Fragment>
        ),
      },
      {
        path: 'compagnies',
        element: (
          <React.Fragment>
            <Companies />
          </React.Fragment>
        ),
      },
      {
        path: 'utilisateurs',
        element: (
          <React.Fragment>
            <Users />
          </React.Fragment>
        ),
      },
      {
        path: 'permissions',
        element: (
          <React.Fragment>
            <PermissionManager />
          </React.Fragment>
        ),
      },
    ],
  },
  {
    path: '/connexion',
    element: <Auth />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
export default function RoutesApp() {
  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  )
}
