import axios, { AxiosInstance } from 'axios'

const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT
class OrdersService {
  private axiosClient: AxiosInstance

  //////////////////////
  // CONSTRUCTOR
  //////////////////////
  constructor(
    client: AxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_REMORA_END_POINT, // Point de terminaison commun
      // maxBodyLength: Infinity, // ajouter si erreur : ERR_FR_MAX_BODY_LENGTH_EXCEEDED
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  ) {
    this.axiosClient = client
  }

  async getOrders(token: string | null, page: any, itemPerPage: number) {
    try {
      const response = await this.axiosClient.get('/api/v1/orders/items/company', {
        params: {
          page: page,
          per_page: itemPerPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (error) {
      console.error('Error fetching data list:', error)
      throw error // Rejette l'erreur pour être gérée par l'appelant
    }
    // return axios.get(
    //   API_URL_TOTARA +
    //     '/api/v1/orders/items/company?page=' +
    //     page +
    //     '&per_page=' +
    //     itemPerPage,
    //   {
    //     headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    //   }
    // )
  }
  getOrdersByIdConnaissement(token: string | null, id: number | undefined) {
    return axios.get(API_URL_TOTARA + '/api/v1/orders/id_connaissement/' + id, {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
  }

  updateOrder(token: string | null, order: any, id: number | null) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/orders/' + id,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: order,
    }

    return axios.request(config)
  }
  updateItemsOrder(token: string | null, order: any, id: number) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/items/' + id,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: order,
    }

    return axios.request(config)
  }
  addItemsOrder(token: string | null, orderData: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/items',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: orderData,
    }

    return axios.request(config)
  }
  filteredOrder(token: string | null, filteringData: any, page: number, itemPerPage: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL_TOTARA}/api/v1/orders/search?page=${page}&per_page=${itemPerPage}&${filteringData}`,
      // url: `${API_URL_TOTARA}/api/v1/orders/search?date_creation=${filteringData?.date_creation}&referenceHorsRevatua=${filteringData?.referenceHorsRevatua}&destinataire_denomination=${filteringData?.destinataire_denomination}&numeroVoyage=${filteringData?.numeroVoyage}&statut_revatua=${filteringData?.statut_revatua}&ileArrivee=${filteringData?.ileArrivee}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    return axios.request(config)
  }
  filteredItem(token: string | null, filteringData: any, id: number | null) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL_TOTARA}/api/v1/items/search?id_order=${id}&detail_referenceExterne=${filteringData?.detail_referenceExterne}&detail_nbColis=${filteringData?.detail_nbColis}&detail_description=${filteringData?.detail_description}&detail_poids=${filteringData?.detail_poids}&detail_stockage=${filteringData?.detail_stockage}&detail_codeTarif=${filteringData?.detail_codeTarif}&detail_codeSH=${filteringData?.detail_codeSH}&detail_contenant=${filteringData?.detail_contenant}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }

    return axios.request(config)
  }

  async getNotification(token: string | null) {
    try {
      const response = await this.axiosClient.get(
        '/api/v1/notifications/latest',
        
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response
    } catch (error) {
      console.error('Error fetching data list:', error)
      throw error
    }
  }

  async patchNotification(token: string | null, id:number) {
    if (!token) {
      throw new Error('Token is missing or invalid');
    }
    try {
      const response = await this.axiosClient.patch(
        `/api/v1/notifications/history/${id}/read`,
        {}, // corps vide
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response
    } catch (error: any) {
      console.error('Error patching data :', error)
      if (error.response) {
        console.error('Response error:', error.response.data)
      } else if (error.request) {
        console.error('Request error:', error.request)
      } else {
        console.error('Unknown error:', error.message)
      }
      throw error
    }
  }
}

const orderServiceInstance = new OrdersService();
export default orderServiceInstance;
// const config = {
//   method: 'patch',
//   maxBodyLength: Infinity,
//   url: 'https://logis.pf/api-remora/public/api/v1/notifications/history/44/read',
//   headers: { 
//     'Content-Type': 'application/json', 
//     'Accept': 'application/json', 
//     'Authorization': 'Bearer ' + token,
//   }
// };

// return axios.request(config)